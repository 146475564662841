<template>
  <v-container fluid :class="$vuetify.breakpoint.name == 'xs' ? 'pt-0' : ''" style="padding-top: 8px;">
    <v-row style="max-height: 87vh; overflow:auto;-webkit-overflow-scrolling:touch;" color="#f1f1f1">
      <v-col cols="1" class="d-none d-md-flex"></v-col>
      <v-col cols="12" sm="12" md="10" class="d-none d-md-flex mr-0 pr-0">
        <div class="subtitle-1 pa-2" style="background-color:white; border-radius: 10px; width:100%; max-height: 50px;">
          <v-icon left>mdi-new-box</v-icon>Nuevas categorías:
          <v-chip
            color="grey darken-4"
            outlined
            class="ml-1 mr-1"
            style="border-width: 0.5px;"
            to="/stores/category/top"
          >
            <v-icon left color="yellow darken-2">mdi-star</v-icon>TOP
          </v-chip>
          <v-chip
            color="grey darken-4"
            outlined
            class="ml-1 mr-1"
            style="border-width: 0.5px;"
            to="/stores/category/new"
          >
            <v-icon left color="red">mdi-alert-decagram</v-icon>NUEVOS
          </v-chip>
          <v-chip
            color="grey darken-4"
            outlined
            class="ml-1 mr-1"
            style="border-width: 0.5px;"
            to="/stores/category/near"
          >
            <v-icon left color="blue">mdi-navigation</v-icon>CERCANOS
          </v-chip>
          <v-chip
            color="grey darken-4"
            outlined
            class="ml-1 mr-1"
            style="border-width: 0.1px !important;"
            to="/stores/category/free"
          >
            <v-icon left color="teal accent-3">mdi-bike</v-icon>DOMI $0
          </v-chip>
        </div>
      </v-col>
      <v-col cols="1" class="d-none d-md-flex"></v-col>
      <v-col cols="1" class="d-none d-md-flex"></v-col>
      <v-col cols="12" sm="2" class="d-none d-md-flex pt-0">
        <v-navigation-drawer
          v-if="categories"
          v-model="drawer"
          color="white"
          permanent
          height="550"
          style="position: -webkit-sticky; position: sticky; border-radius: 10px; top: 8px;"
        >
          <template v-slot:prepend>
            <v-list-item>
              <h3>
                <v-icon left>mdi-tag-multiple</v-icon>Categorías
              </h3>
            </v-list-item>
          </template>

          <v-list dense nav>
            <v-list-item-group color="primary">
              <v-list-item v-for="item in categories" :key="item" link>
                <v-list-item-content @click="changeCategoryStore(item)">
                  <v-list-item-title style="font-size: medium; line-height: 1.5rem;">{{ item }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>

        </v-navigation-drawer>
                
      </v-col>

      <v-col cols="12" sm="12" md="8" class="pt-0" style="overflow:auto;-webkit-overflow-scrolling:touch">
        <v-row style="background-color:white; border-radius: 10px;">
         
          <v-col cols="12" class="pb-0" v-if="category"><h3> <v-icon left>{{getTitleText(category).icon}}</v-icon> {{ getTitleText(category).name }}</h3></v-col>

          <v-col cols="12" class="d-flex d-sm-flex d-md-none pl-0 pr-0 pb-0 pt-1">
  
            <v-slide-group show-arrows>
              <v-slide-item
                v-slot:default="{ active }"
              >
                <v-btn
                  class="mx-1"
                  :input-value="active"
                  active-class="grey white--text"
                  depressed
                  rounded
                  outlined
                  small
                  to="/stores/category/top"
                >
                  <v-icon left color="yellow darken-2">mdi-star</v-icon>TOP
                </v-btn>
              </v-slide-item>
              <v-slide-item
                v-slot:default="{ active }"
              >
                <v-btn
                  class="mx-1"
                  :input-value="active"
                  active-class="grey white--text"
                  depressed
                  rounded
                  outlined
                  small
                  to="/stores/category/new"
                >
                  <v-icon left color="red">mdi-alert-decagram</v-icon>NUEVOS
                </v-btn>
              </v-slide-item>
              <v-slide-item
                v-slot:default="{ active }"
              >
                <v-btn
                  class="mx-1"
                  :input-value="active"
                  active-class="grey white--text"
                  depressed
                  rounded
                  outlined
                  small
                   to="/stores/category/near"
                >
                  <v-icon left color="blue">mdi-navigation</v-icon>CERCANOS
                </v-btn>
              </v-slide-item>
              <v-slide-item
                v-slot:default="{ active }"
              >
                <v-btn
                  class="mx-1"
                  :input-value="active"
                  active-class="grey white--text"
                  depressed
                  rounded
                  outlined
                  small
                  to="/stores/category/free"
                >
                   <v-icon left color="teal accent-3">mdi-bike</v-icon>DOMI $0
                </v-btn>
              </v-slide-item>
            </v-slide-group>
          
          </v-col>

          <v-col cols="12" class="d-flex d-sm-flex d-md-none pl-2 pr-3 pb-0 pt-2 mt-2 justify-end">
            <v-select
              dense
              v-model="cat_model"
              :items="categories"
              label="Categorías"
              outlined
              hide-details
              style="max-width: 200px;"
              class="float-right"
              mandatory
              @input="changeCategoryStore(cat_model)"
            ></v-select>
          </v-col>

          <v-col cols="12" v-if="stores && stores.length > 0">
            <v-row class="pa-2 pt-0">
              <v-col
                v-for="(store, index) in stores"
                :key="index"
                class="d-flex child-flex pa-1"
                cols="12"
                sm="6"
                md="4"
              >
              
              <v-list-item three-line :style="`${store.attributes.status != 'active' ? `background-image: linear-gradient(rgba(33, 33, 33, 0.1), rgba(33, 33, 33, 0.1)); background-position: center center;` : ''} border-bottom: 1px ridge;`" @click="goStore(store)" :disabled="store.attributes.status == 'active' ? false : true">
                  <v-list-item-avatar
                    size="80"
                    color="grey"
                >
                  <v-img v-if="store.attributes.logoUrl" :src="store.attributes.logoUrl" :lazy-src="store.attributes.logoUrl"></v-img>
                </v-list-item-avatar>
                <v-list-item-content style="align-self: center !important;">
                     <v-chip
                        v-if="store.attributes.status == 'inactive'"
                        class="float-right"
                        small
                        style="max-width: 90px;"
                      >
                        <v-icon left small>mdi-lock-outline</v-icon>Cerrado
                      </v-chip>
                    <v-list-item-title class="subtitle-1 mb-1">
                    {{ store.attributes.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="store.attributes.storeCategory" class="mb-1">
                      {{ store.attributes.storeCategory.attributes.name }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle> <v-icon left small>mdi-bike</v-icon> {{ store.attributes.delivery ? store.attributes.delivery.timeCaption: '' }} <span class="float-right" v-if="store.attributes.rating"><v-icon small color="yellow accent-4">mdi-star</v-icon> {{store.attributes.rating}} </span></v-list-item-subtitle>
                </v-list-item-content>

                </v-list-item>

              </v-col>
              
            </v-row>
          </v-col>

          <v-col cols="12" v-else-if="!$store.state.loading" style="text-align: center;">
            <div class="ma-5">
              <v-icon>mdi-emoticon-excited-outline</v-icon>
              <br />Aún no hay comercios de esta categoría en tu zona, pero pronto encontraras las mejores promociones.
            </div>
          </v-col>
        </v-row>
      </v-col>
      
      <v-col cols="1" class="d-none d-md-flex"></v-col>
      
    </v-row>

  </v-container>
</template>

<script>
//import Parse from "parse";
//import { _ } from "vue-underscore";
import Parse from 'parse'

export default {
  data() {
    return {
      drawer: true,
      active: "",
      promos: [],
      stores: [],
      stores_all: [],
      category: '',
      categories: [],
      cat_model: '',
      dialogAddItems: false,
      promotion: '',
      quantity: 1,
      modifiers: '',
      comboOption: '',
      comboPrice: '',
      selectModifiers: [],
      itemsBox: [],
      itemsCheck: [],
      descriptionComplete: false,
      location: ''
    };
  },
  
  components:{
  },

  mounted() {
    this.category = this.$route.params.category
    this.location = JSON.parse(window.localStorage.getItem("festyZoneLocation"));
    if (!this.$store.state.zone && !location) {
        this.$router.push('/')
    }

    this.initialize();
  },

  beforeDestroy(){
    
  },

  beforeUpdate() {
  },
  
  updated() {
      
  },

  methods: {
    async initialize() {
        try {
            if (!this.category) {
                this.$snotify.error(`Categoría no definida.` , 'Comercios', {
                    timeout: 4000,
                    preventDuplicates: true
                });
                return;
            }
            let params = {
                latitude: this.location ? this.location.latitude : this.$store.state.zone.latitude ? this.$store.state.zone.latitude : '',
                longitude: this.location ? this.location.longitude : this.$store.state.zone.longitude ? this.$store.state.zone.longitude : '',
                radius: this.location ? this.location.radius : this.$store.state.zone.radius ? this.$store.state.zone.radius : '',
            }
            //console.log(params);
            let stores = ''
            switch (this.category) {
                case 'top':
                    params.useGeoCoverage = true
                    stores = await Parse.Cloud.run("getTopStores", params);
                    break;

                case 'new':
                    params.useGeoCoverage = true
                    stores = await Parse.Cloud.run("getNewStores", params);
                    break;

                case 'near':
                    params.distance = this.$store.state.zone.radius ? this.$store.state.zone.radius : ''
                    stores = await Parse.Cloud.run("getNearStores", params);
                    break;

                case 'free':
                    params.useGeoCoverage = true
                    stores = await Parse.Cloud.run("getFreeDeliveryStores", params);
                    break;
                
                default:
                    this.$router.push('/')
                    break;
                }
                
                
                this.stores = stores
                this.stores_all = stores
                //eslint-disable-next-line
                //console.log(stores);
                this.getCategoriesStore(stores)
             
            } catch (error) {
                console.log(error);
                this.$snotify.error(`${error}` , 'Comercios', {
                    timeout: 4000,
                    preventDuplicates: true
                });
        }
    },

    async changeCategoryStore(item) {
      try {
            if (item == 'Todas') {
              this.stores = this.stores_all
              return
            }

            this.stores = this.stores_all.filter(store => {
             if (store.attributes.storeCategory && store.attributes.storeCategory.attributes.name === item) {
               return store
             }
           })
          
      } catch (error) {
        this.$snotify.error(`${error}` , 'Filtro de categorías', {
          timeout: 4000,
          preventDuplicates: true
        });
      }
    },
    

    getBreakPointCol(){
      switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 1
          case 'sm': return 2
          case 'md': return 2
          case 'lg': return 2
          case 'xl': return 2
        }
    },

    getTitleText(category){
      switch (category) {
        case 'top': return {name: 'TOP', icon: 'mdi-star'}
        case 'new': return {name: 'Nuevos', icon: 'mdi-alert-decagram'}
        case 'near': return {name: 'Cercanos', icon: 'mdi-navigation'}
        case 'free': return {name: 'Domicilio gratis', icon: 'mdi-bike'}
        default: return ''
      }
    },

    getCategoriesStore(stores){
      let categories = stores.map(o => {
        if (o.attributes.storeCategory) {
          return o.attributes.storeCategory.attributes.name
        }
      })
      categories = Array.from(new Set(categories))
      categories = categories.filter(Boolean).sort();
      categories.unshift('Todas')
      this.categories = categories
      this.cat_model = categories[0]
    },

    goStore(store){
      this.$router.push(`/store/${store.attributes.slug ? store.attributes.slug : store.id}`)
    }
   
  }
};
</script>

<style lang="css">

.circle {
  width: 34px;
  height: 34px;
  background: teal;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  border: 1px solid #9e9e9e;
}

.slick-prev:before{
  color: black;
}

.slick-next:before{
  color: black;
}
</style>